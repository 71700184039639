// Components
import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import HearingService from '@/services/API/hearings.service';
import Badge from '@/components/Badge/StatusBadge.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
// Services
import socketClient from '@/services/SOCKET';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import DecisionService from '@/services/API/decision.service';
import ScheduleService from '@/services/API/schedule.service';
// Consts
import CommonConst from '@/constants/CommonConst';
import HearingConst from '@/constants/HearingConst';

// Library & utilities
import moment from 'moment';
import { mapState } from 'vuex';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import autoHeightTextArea from '@/mixin/autoHeightTextArea';

export default {
	name: 'HearingPage',
	mixins: [updateProjectStatusMixin, autoHeightTextArea],
	props: {
		mobileView: Boolean,
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			//edit code nextProcess, screenName 01/08/2023 by HoaDQ
			nextProcess: 'decision',
			screenIcon: 'hearing-icon',
			screenName: 'キックオフミーティング',
			availableWhenClick: false,
			availableValidate: false,
			hearingContents: '',
			hearingDetails: {},
			hearingId: undefined,
			selectiveContents: HearingConst.SELECTIVE_NOTI_CONTENTS,
			errorContents: '',
			isTextareaDisabled: false,
			selectiveModalId: 'hearing-selective-modal',
			plainModalId: 'hearing-plain-modal-id',
			plainContents: '保存しました',
			middleContent: ' 様 ／ ',
			buttonType: '',
			hearingStatus: '',
			bottomButtonName: '',
			badgeContent: '',
			mailContent: '',
			mailTitle: '',
			subject: '',
			isSendCreator: false,
			placeholderContent: HearingConst.PLACEHOLDER_CONTENT,
			saveTimeout: null,
			// Show main content after fetch data from server successfully
			isShowContent: false,
		};
	},
	components: {
		ScreenHeader,
		SaveButtonArea,
		BottomButton,
		NotificationModal,
		Badge,
		ProjectDeadline,
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'schedule',
			'creatorInfo',
			'managementMasterInfo',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		saveButtonDisabled() {
			if (
				!this.hearingContents ||
				this.hearingStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM ||
				this.isTextareaDisabled
			) {
				console.log('on-triger', this.hearingStatus);
				return true;
			} else return false;
		},
		showStatusBadge() {
			if (this.hearingStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.badgeContent = 'クライアント承認待ち';
				return true;
			} else if (this.hearingStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM) {
				this.isTextareaDisabled = false;
				this.badgeContent = 'クライアントが承認しませんでした';
				return true;
			} else return false;
		},
		showSaveButton() {
			if (
				this.hearingStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				this.hearingStatus === CommonConst.SCREEN_STATUS.FINISH
			) {
				return false;
			} else return true;
		},
		showBottomButton() {
			if (
				!this.hearingContents ||
				this.hearingStatus === CommonConst.SCREEN_STATUS.FINISH ||
				this.hearingStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM ||
				this.hearingStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM
			) {
				return false;
			} else return true;
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}hearing`
						: `${this.projectInfo['process_client_url']}hearing`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		/* eslint-disable no-mixed-spaces-and-tabs */
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  };
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}hearing`
					: `${this.projectInfo['process_client_url']}hearing`,
			});
		},
	},
	watch: {
		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		hearingStatus(newStatus) {
			if (
				newStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM ||
				newStatus === CommonConst.SCREEN_STATUS.DENY_CONFIRM
			) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.HEARING]);
			} else {
				const redDotIndexs = this.removeScreenId(
					this.redDotIndexs,
					CommonConst.SCREEN_ID.HEARING
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},
		// isShowContent() {
		// 	if (this.mobileView)
		// 		this.setAutoHeightTextArea(
		// 			'hearing-Textarea',
		// 			HearingConst.TEXT_AREA_MIN_HEIGHT,
		// 			true
		// 		);
		// },
		// hearingContents: function (newHearingContents) {
		// 	this.onSaveClick(); // Gọi hàm save khi hearingContents thay đổi
		// },
	},
	methods: {
		/**
		 *
		 * @param {Array} redDotIndexs
		 * @param {Integer} removingScreenId
		 * @return {Array} Array after remove screenId
		 */
		removeScreenId(redDotIndexs, removingScreenId) {
			return redDotIndexs.filter((screenId) => screenId !== removingScreenId);
		},

		async onDirectClick() {
			this.$store.commit('setIsAppProcessing', false);
			let response = await DecisionService.get(this.projectId);
			if (!response || response.status !== 200) {
				throw 'Get Decision failed';
			}
			if (response.data['project_id'] !== this.projectId) {
				await this._createNewDecision();
			}
			let confirmInfo = {
				screenId: CommonConst.SCREEN_ID.HEARING,
				confirmInfo: true,
			};
			// let status = {
			// 	status: CommonConst.SCREEN_STATUS.FINISH,
			// 	confirm_flag: 1,
			// };
			await this._updateHearingsStatus(this.projectId, {
				status: CommonConst.SCREEN_STATUS.FINISH,
				confirm_flag: 1,
			});
			if (this.projectInfo['status'] === CommonConst.SCREEN_ID.HEARING) {
				this.$emit('on-send-confirm-info', confirmInfo);
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'direct_to_next_page',
					},
					room: this.projectId,
				});
				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.HEARING + 1
				);
				this.hearingStatus = CommonConst.SCREEN_STATUS.FINISH;
			}

			// // send mail case 10
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「コンセプトデザイン」スタート`;
			// this.mailContent = `「コンセプトデザイン」を\nスタートします。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「コンセプトデザイン」スタート`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「コンセプトデザイン」スタート`;
			// this.mailContent = `「コンセプトデザイン」を\nスタートします。`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「コンセプトデザイン」スタート`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);
			this.$router.push(this.nextProcess);

			this.$store.commit('setIsAppProcessing', false);
		},
		async onSaveClick() {
			console.log('Save Hearing');
			this.$store.commit('setIsAppProcessing', true);

			this.availableWhenClick = !this.availableWhenClick;
			this._validate();
			let status = 'onSave';

			if (this._isObjectEmpty(this.hearingDetails)) {
				let hearingDetail = {
					project_id: this.projectId,
					update_user: this.userId,
					decision: this.hearingContents,
					status: status,
				};

				await this._createHearings(hearingDetail);
				this._checkInitialState();
				// this.$bvModal.show(this.plainModalId);

				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			await this._updateHearingsStatus(this.projectId, status);
			await this._updateHearingsContent(this.projectId, this.hearingContents);
			await this._checkInitialState();

			// this.$bvModal.show(this.plainModalId);
			this.$store.commit('setIsAppProcessing', false);
		},

		_validate() {
			setTimeout(() => {
				this.availableWhenClick = !this.availableWhenClick;
				this.availableValidate = !this.availableValidate;
				this._callback();
			}, 2250);
		},
		_callback() {
			setTimeout(() => {
				this.availableValidate = !this.availableValidate;
			}, 1250);
		},

		onInputHearings() {
			if (this.saveTimeout) {
				clearTimeout(this.saveTimeout);
			}
			this.saveTimeout = setTimeout(() => {
				this.onSaveClick();
			}, 1000);
			//Send input contents to client when typing
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'hearing_edit',
					content: this.hearingContents,
				},
				room: this.projectId,
			});
		},

		onConfirm() {
			this.$bvModal.show(`${this.selectiveModalId}`);
			this.selectiveContents = HearingConst.SELECTIVE_NOTI_CONTENTS;
		},

		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			this.isTextareaDisabled = true;
			let hearingStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;
			let result = await this._updateHearingsStatus(this.projectId, hearingStatus);
			await this._updateHearingsContent(this.projectId, this.hearingContents);

			if (result) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'hearing_confirm_required',
					},
					room: this.projectId,
				});
				// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「ヒアリング」確定依頼を送りました`;
				// this.mailContent = `「ヒアリング」の\n確定依頼を送りました。`;
				// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「ヒアリング」確定依頼を送りました`;
				// this.isSendCreator = true;
				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['responsible_user'],
				// ]);
				// this.mailContent = `「ヒアリング」の\n確定依頼が届きました。\n内容を確認し、承認してください。`;
				// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「ヒアリング」確定依頼が届きました`;
				// this.isSendCreator = false;
				// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「ヒアリング」確定依頼が届きました`;

				// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				// 	this.projectInfo['client_id'],
				// ]);
				this._disableElements();

				this.$store.commit('setIsAppProcessing', false);
			} else {
				console.log('%c Can not send confirm require', 'color: red');
			}
		},

		/**
		 * Get hearing contents displayed in text box
		 * @param {Integer} projectId project identifier
		 * @returns {Object} Hearing details
		 */
		async _getHearings(projectId) {
			let response = await HearingService.get(projectId);
			if (response && response.status === 200) {
				this.hearingId = response.data['id'];
				return response.data;
			} else {
				// TODO: Error notification
				console.log('Get hearing details failed');
			}
			return;
		},

		/**
		 * Create hearing detail
		 * @param {Object} content hearing detail
		 */
		async _createHearings(content) {
			try {
				let response = await HearingService.post({ doc_content: content });

				if (!response || response.status !== 200) {
					throw 'Create Requiremnt failed';
				}
				this.hearingId = response.data.id;
				console.log('%c Create Hearing successfully', 'color: green');
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 *
		 * @param {Integer} projectId project id
		 * @param {String} content hearingContents
		 */
		async _updateHearingsContent(projectId, content) {
			try {
				let response = await HearingService.update(projectId, {
					decision: content,
				});
				if (!response || response.status !== 200) {
					console.log('%c Update Hearing failed', 'color: red');
					return false;
				}
				console.log('%c Update Hearing successfully', 'color: green');
				return true;
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 *
		 * @param {Integer} projectId project id
		 * @param {String} status hearing status
		 */
		async _updateHearingsStatus(projectId, status) {
			try {
				let response = await HearingService.update(projectId, status);
				if (!response || response.status !== 200) {
					console.log('%c Update Hearing failed', 'color: red');
					return false;
				}
				console.log('%c Update Hearing successfully', 'color: green');
				return true;
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Check textbox contents and confirm flag
		 * If confirm flag equal 1 -> Disable textbox and hide save(保存) button
		 */
		async _checkInitialState() {
			let hearingDetails = await this._getHearings(this.projectId);
			this.hearingDetails = hearingDetails;

			if (hearingDetails) {
				this.hearingContents = hearingDetails.decision;
				this.hearingStatus = hearingDetails.status;
				this._onChangeBottomButtonType(this.hearingStatus);
				if (this.hearingStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
					this._disableElements();
				} else if (this.hearingStatus === CommonConst.SCREEN_STATUS.FINISH) {
					this._disableElements();
				} else {
					this.isButtonShowed = true;
				}
			}
		},

		_onChangeBottomButtonType(status) {
			if (status === 'onSave') {
				this.buttonType = 'direct-screen-btn';
				this.bottomButtonName = 'コンセプトデザインに進む';
				// } else if (status === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM) {
				// 	this.buttonType = 'direct-screen-btn';
				// 	this.bottomButtonName = 'スケジュールに進む';
			}
		},

		/**
		 * Disable textbox and hide save(保存) button
		 */
		_disableElements() {
			this.isTextareaDisabled = true;
		},

		_isObjectEmpty(obj) {
			for (var x in obj) {
				return false;
			}
			return true;
		},
		/**
		 * Create new Decision record
		 */
		async _createNewDecision() {
			let decisionData = {
				project_id: this.projectId,
				image_list: [],
				update_user: this.userId,
			};

			let response = await DecisionService.post({
				doc_content: decisionData,
			});

			if (!response || response.status !== 200) {
				throw 'Create Decision failed';
			}

			console.log('%c Create Decision successfully', 'color: green');
		},
		async getScheduleInfo(projectId) {
			let response = await ScheduleService.get(projectId);
			if (response && response.status === 200) {
				console.log('Get schedule successfully');
				let data = response.data;
				this.scheduleInfo = data;
				this.$store.commit('setSchedule', data);
			} else {
				console.log('Get schedule failed');
			}
		},
	},

	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'hearing_accept_confirm':
					this.hearingStatus = CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM;
					console.log('hearing_accept_confirm');
					this._onChangeBottomButtonType(CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM);
					break;

				case 'hearing_deny_confirm':
					this.hearingStatus = CommonConst.SCREEN_STATUS.DENY_CONFIRM;
					break;
				case 'client_mode_on':
					socketClient.send('data_transfer', {
						data: {
							event_name: 'send_client_mode',
							content: this.hearingContents,
						},
						room: this.projectId,
					});
					break;
				default:
					break;
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);
		await this.getScheduleInfo(this.projectId);
		await this._checkInitialState();
		moment.locale('ja');
		this.isShowContent = true;

		this.$store.commit('setIsAppProcessing', false);
	},
};
