import ApiService from './api.service';

const serviceURL = 'schedule';
const ScheduleService = {
	/**
	 * Acquire Schedule by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Schedule details
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new Schedule record
	 * @param {Object} body {doc_content: {schedule Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Schedule content from datePicker
	 * @param {Integer} projectId: Project ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		console.log(body);
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},

	/**
	 * confirm schedule content
	 * @param {Integer} projectId: Project ID
	 * @returns
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`);
	},
};

export default ScheduleService;
