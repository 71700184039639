<template>
	<div class="badge">
		{{ badgeContent }}
	</div>
</template>

<script>
export default {
	name: 'StatusBadge',
	props: {
		badgeContent: {
			type: String,
		},
	},
};
</script>

<style>
.badge {
	height: 21px;
	color: white;
	font-size: 11px;
	border-radius: 3px;
	text-align: center;
	background-color: #ff7a69;
	font-family: NotoSansJPMedium;
}
</style>
