import { checkMobileScreen } from '@/store/modules/ScreenWidth';

const TEXT_AREA_MIN_HEIGHT = 244;

const PLACEHOLDER_CONTENT = '・ロゴを作成したい\n・赤色を入れたいです\n・黒色はNGです';

const SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? '「ヒアリング」承認依頼をクライアントに\n通知します'
	: '「ヒアリング」承認依頼をクライアントに通知します';

export default {
	PLACEHOLDER_CONTENT,
	SELECTIVE_NOTI_CONTENTS,
	TEXT_AREA_MIN_HEIGHT,
};
