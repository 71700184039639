export default {
	methods: {
		/**
		 * A hack to set textarea height fit its text content
		 * @param {string} className className of textarea elements want to set
		 * @param {Integer} minHeight textarea min height
		 */
		setAutoHeightTextArea(className, minHeight) {
			this.$nextTick(() => {
				const OnInput = function () {
					if (this.scrollHeight > minHeight) {
						this.style.height = 0;
						this.style.height = this.scrollHeight + 'px';
					}
				};

				let textAreas = document.getElementsByClassName(className);

				for (const textArea of textAreas) {
					textArea.setAttribute(
						'style',
						'height:' + textArea.scrollHeight + 'px;overflow-y:hidden;'
					);

					textArea.addEventListener('input', OnInput);

					textArea.dispatchEvent(new Event('input'));
				}
			});
		},
	},
};
