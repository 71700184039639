import ApiService from './api.service';

const serviceURL = 'hearing';
const HearingService = {
	/**
	 * Acquire hearing by projectID
	 * @param {Integer} projectId
	 * @returns {Object} Hearings
	 */
	get(projectId) {
		return ApiService.get(`${serviceURL}`, `${projectId}`);
	},

	/**
	 * Create new hearing
	 * @param {Object} body {doc_content: {hearing Object}}
	 * @returns {Object} include success message
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update hearing content from text box
	 * @param {Integer} projectId: Project ID
	 * @param {Object} contents: Hearing contents from text box
	 * @returns
	 */
	update(projectId, contents) {
		let body = {
			doc_content: contents,
		};
		// console.log(body)
		return ApiService.put(`${serviceURL}/${projectId}`, body);
	},

	/**
	 * confirm hearing content
	 * @param {Integer} projectId: Project ID
	 * @returns
	 */
	confirm(projectId) {
		return ApiService.put(`${serviceURL}/${projectId}/confirm`);
	},
};

export default HearingService;
